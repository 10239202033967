import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { COUNTRY_BASE_FRAGMENT } from '@/graphql/_Fragments/Country/Base';
import {
  COMMUNITY_USER_CONNECTION_FULL_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserConnection/Full';

export const COMMUNITY_USER_CARD_FRAGMENT = `
  fragment communityUserCardFragment on CommunityUser {
    id
    uid
    schemaCode
    firstName
    lastName
    name
    prefix
    suffix
    jobTitle
    employerName
    pictureFileResource {
      ...fileResourceBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    _ourConnection(myUid: "%authUser%") {
      ...communityUserConnectionFullFragment
    }
    _isBlocked(myUid: "%authUser%")
    address {
      uid
      country {
        ...countryBaseFragment
      }
    }
    _actions (actions: ["CAN_MEET", "CAN_CONNECT"]) {
      value
      key
    }
    _isRecommendedForMe
  }
  ${COMMUNITY_USER_CONNECTION_FULL_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${COUNTRY_BASE_FRAGMENT}
`;
